/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Box, Card, CardContent, Link, Typography } from '@mui/material';
import { Helmet } from "react-helmet";
import { FaAngleRight } from 'react-icons/fa';
import { styled, keyframes } from '@mui/system';
import siteLinks from './SiteLinks';  // Import the siteLinks

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const hoverAnimation = keyframes`
  0% { color: #333333; }
  100% { color: #15a2fa; }
`;

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'block',
    marginBottom: '1rem',
    color: '#333333',
    textDecoration: 'none',
    '&:hover': {
        animation: `${hoverAnimation} 0.3s forwards`
    }
}));

const SiteIndex = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Site Index - Pop Zombie</title>
                <link rel="canonical" href={`https://www.pop-zombie.com/siteIndex`} />
                <meta
                    name="description"
                    content="Pop Zombie - Find all the URLs on Pop-Zombie.com."
                />
                <link rel="alternate" hrefLang="en" href={`https://www.pop-zombie.com/siteIndex`} />
                <link rel="alternate" hrefLang="x-default" href={`https://www.pop-zombie.com/siteIndex`} />
                <meta property="og:title" content="Pop Zombie - Site Index" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/KieXhJW.png" />
                <meta property="og:url" content="https://www.pop-zombie.com/siteIndex" />
            </Helmet>

            <Box sx={{ marginTop: '0px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                    <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#ffffff', borderRadius: '15px', width: '100%', mt: 2 }}>
                        <CardContent sx={{ padding: '14px 0px' }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                                Site Index
                            </Typography>
                            <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: 'false' }}>
                                {siteLinks.map((link, index) => (
                                    <StyledLink
                                        key={index}
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaAngleRight style={{ marginRight: '10px', color: '#15a2fa' }} />
                                        {link.title}
                                    </StyledLink>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Container>
    );
};

export default SiteIndex;
