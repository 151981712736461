/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Form, FormControl, ListGroup, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import ImageSlideShow from '../components/ImageSlideShow';
import Post from '../components/Post';
import ReactPaginate from 'react-paginate';
import '../components/Pagination.css';
import { FcPrevious, FcNext } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts, reset } from '../features/posts/postSlice';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';
import formatDistance from 'date-fns/formatDistance';
import { Helmet } from 'react-helmet';
import './homeStyles.css';


// import { FaSearch } from 'react-icons/fa';

const Home = () => {

  const placeholderAdsRight = [
    {
      title: "Wagzters",
      caption: "Pet Social Network and Services",
      link: "https://wagzters.com"
    },
    // Add more ads as needed
    {
      title: "CineQL",
      caption: "Internet Movie Database",
      link: "https://www.cineql.com"
    },
    {
      title: "SloppyNet",
      caption: "Let's celebrate small achievements!",
      link: "https://sloppynet.com"
    },
    {
      title: "TinyPortal",
      caption: "Tiny Web Directory",
      link: "https://tinyportal.org"
    },
    {
      title: "NiteStack",
      caption: "Videos",
      link: "https://nitestack.com"
    },



  ];

  const placeholderAdsLeft = [
    {
      title: "DirtStack",
      caption: "Dirt Cheap eShop",
      link: "https://dirtstack.com"
    },
    // Add more ads as needed
    {
      title: "Dev Principia",
      caption: "Tech Blog",
      link: "https://dev-principia.com"
    },
    {
      title: "Limbonaut",
      caption: "Apps",
      link: "https://www.limbonaut.com"
    },
    {
      title: "Beer Utopia",
      caption: "Brewery Directory",
      link: "https://www.beer-utopia.com"
    },
    {
      title: "CryptoPhoton",
      caption: "Crypto",
      link: "https://www.cryptophoton.com"
    },



  ];

  const [toggleSlideShow, setToggleSlideShow] = useState(true);
  // Define some vars for pagination function
  const [pageNumber, setPageNumber] = useState(0);
  const [postList, setPostList] = useState({});
  const dispatch = useDispatch()
  const { posts, isLoading, isError, message } = useSelector((state) => state.posts)

  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = [...new Set(posts.map((post) => post.city))];
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const filteredPosts = selectedCategory
      ? posts.filter((post) => post.city === selectedCategory)
      : posts;

  const [isLinkHoveredRight, setIsLinkHoveredRight] = useState(Array(placeholderAdsRight.length).fill(false));

  const [isLinkHoveredLeft, setIsLinkHoveredLeft] = useState(Array(placeholderAdsLeft.length).fill(false));






  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    dispatch(getAllPosts())

    return () => {
      dispatch(reset())
    }
  }, [isError, message, dispatch])

  const searchPost = async (e) => {
    const searchValue = e.target.value;
    if (searchValue.length < 1) {return}
    await axios.get(`/api/posts/search?text=${searchValue}`)
      .then(res => {
        setPostList(res.data)
      })
      .catch(err => toast.error(err));
  };

  if (isLoading) {
    return <Spinner />
  }
  // Set the number of items for each page
  const postsPerPage = 10;
  const pagesVisited = pageNumber * postsPerPage;
  // This function is used to display the set of items on the home page
  const displayPosts = filteredPosts
    .slice(pagesVisited, pagesVisited + postsPerPage)
    .map((post, idx) => {
      return (
        <Post key={idx} post={post} showUsername={true} fromFavoritePostPage={false} />
      );
    });

  const pageCount = Math.ceil(posts.length / postsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (

        <Container fluid>

          <Helmet>
            <title>Pop Zombie - Home</title>
            <meta property="og:title" content="Pop Zombie - Your Ultimate Travel Companion and Guide" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://i.imgur.com/KieXhJW.png" />
            <meta property="og:url" content="https://www.pop-zombie.com" />
            <meta property="og:description" content="Explore the world of Pop Zombie, your go-to hub for captivating content on travel, lifestyle, and more. Join us, share your favorites, and discover your next adventure! " />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Pop Zombie - Your Ultimate Travel Companion and Guide" />
            <meta name="twitter:description" content="Pop Zombie offers the best travel tips, guides, and affiliate deals to make your trips memorable. Discover top destinations, travel hacks, and hidden gems. Join our community today!" />
            <meta name="twitter:image" content="https://i.imgur.com/KieXhJW.png" />
            <meta name="twitter:site" content="@PopZombieLite" />
          </Helmet>


          <div>
            <Container fluid>
              <Row>

                <Col>

                  {/* Categories are moved to the top right next to the button */}
                  <div className="d-flex flex-wrap justify-content-center align-items-center mt-2">
                    {categories.map((category, index) => (
                        <Button
                            key={index}
                            variant="outline-primary"
                            onClick={() => handleCategoryClick(category)}
                            style={{
                              backgroundColor: '#f0f2f5',
                              borderColor: '#f0f2f5',
                              color:'#ee3d5b',
                              margin: '0 10px 10px 0'
                            }}
                        >
                          {category}
                        </Button>
                    ))}
                  </div>

                </Col>
              </Row>

              <Row>
                {/* Dividing the screen into 3 columns */}
                {/* Advertising Left Column */}

                <Col md={3}>
                </Col>

                {/*<Col md={4}>*/}
                {/*  {placeholderAdsLeft.map((ad, index) => (*/}
                {/*      <div*/}
                {/*          key={index}*/}
                {/*          className="card mt-4"*/}
                {/*          style={{*/}
                {/*            marginTop: '20px',*/}
                {/*            padding:'50px',*/}
                {/*            backgroundColor:'#f0f2f5',*/}
                {/*            border:'none'*/}
                {/*          }}*/}
                {/*      >*/}
                {/*        <div*/}
                {/*            className="card-body"*/}
                {/*            style={{*/}
                {/*              marginTop:'10px',*/}
                {/*              textAlign: 'center' // This will center-align the content*/}
                {/*            }}*/}
                {/*        >*/}
                {/*          <a*/}
                {/*              href={ad.link}*/}
                {/*              target="_blank"*/}
                {/*              rel="noopener noreferrer"*/}
                {/*              style={{*/}
                {/*                color: isLinkHoveredLeft[index] ? "#ee3d5b" : "#15a2fa",*/}
                {/*                textDecoration: 'none'  // To remove the underline for the link*/}
                {/*              }}*/}
                {/*              onMouseEnter={() => {*/}
                {/*                const newIsLinkHovered = [...isLinkHoveredLeft];*/}
                {/*                newIsLinkHovered[index] = true;*/}
                {/*                setIsLinkHoveredLeft(newIsLinkHovered);*/}
                {/*              }}*/}
                {/*              onMouseLeave={() => {*/}
                {/*                const newIsLinkHovered = [...isLinkHoveredLeft];*/}
                {/*                newIsLinkHovered[index] = false;*/}
                {/*                setIsLinkHoveredLeft(newIsLinkHovered);*/}
                {/*              }}*/}
                {/*          >*/}
                {/*            <h5 className="card-title">*/}
                {/*              {ad.title}*/}
                {/*            </h5>*/}
                {/*          </a>*/}
                {/*          <p className="card-text">{ad.caption}</p>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*  ))}*/}
                {/*</Col>*/}



                {/* Posts content column */}
                <Col md={6}>

                  {/* Post-List area */}
                  <Col>
                    <div>
                      <Row className="mb-2">
                        <Col>
                          <Form className='mt-2'>
                            <FormControl
                                type="search"
                                placeholder="Search..."
                                className="me-5"
                                aria-label="Search"
                                onChange={searchPost} // onChange will trigger "search post"

                            />
                          </Form>
                        </Col>

                      </Row>
                    </div>

                    <Row>
                      {postList.length > 0 ? (
                          <ListGroup variant="flush" as="ol">
                            {
                              postList.map((post) => {
                                return (
                                    <ListGroup.Item key={post._id}>
                                      <div className="fw-bold h4" style={{
                                        color: "#ee3d5b"
                                      }}>
                                        <Link to={`/postDetail/${post.country}`} style={{
                                          textDecoration: 'none',
                                          color: "#ee3d5b"
                                        }}>{post.title}</Link>
                                      </div>
                                      <div style={{
                                        color: "#b8dfe6"
                                      }}>{post.content} - <span className="text-secondary">{`Last modified: ${formatDistance(new Date(post.updatedAt), new Date())}`}</span></div>
                                    </ListGroup.Item>
                                );
                              })
                            }
                          </ListGroup>) : null}
                      <div>
                        <Row className="mb-2">
                          {/* Image Slide Show area */}
                          <div className={toggleSlideShow ? "show" : "hide"}>
                            <h3 className='title mb-2' style={{
                              color: "#15a2fa"
                            }}>New Stories</h3>
                            {posts.length > 0 ? (<ImageSlideShow posts={posts} />) : ('')}
                          </div>
                        </Row>
                      </div>
                      <h3 className='title mb-2' style={{
                        color: "#15a2fa"
                      }}>Pop List</h3>
                      {posts.length > 0 ? (
                          <>
                            {displayPosts}
                          </>
                      ) : ('')
                      }
                    </Row>

                    {/* Rendering the pagination buttons */}
                    <Row className='pt-2'>
                      <ReactPaginate
                          previousLabel={<FcPrevious />}
                          nextLabel={<FcNext />}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                      />
                    </Row>

                  </Col>

                </Col>

                {/* Advertising Right Column */}

                <Col md={3}>
                </Col>

                {/*<Col md={4}>*/}
                {/*  {placeholderAdsRight.map((ad, index) => (*/}
                {/*      <div*/}
                {/*          key={index}*/}
                {/*          className="card mt-4"*/}
                {/*          style={{*/}
                {/*            marginTop: '20px',*/}
                {/*            padding:'50px',*/}
                {/*            backgroundColor:'#f0f2f5',*/}
                {/*            border:'none'*/}
                {/*          }}*/}
                {/*      >*/}
                {/*        <div*/}
                {/*            className="card-body"*/}
                {/*            style={{*/}
                {/*              marginTop:'10px',*/}
                {/*              textAlign: 'center' // This will center-align the content*/}
                {/*            }}*/}
                {/*        >*/}
                {/*          <a*/}
                {/*              href={ad.link}*/}
                {/*              target="_blank"*/}
                {/*              rel="noopener noreferrer"*/}
                {/*              style={{*/}
                {/*                color: isLinkHoveredRight[index] ? "#ee3d5b" : "#15a2fa",*/}
                {/*                textDecoration: 'none'  // To remove the underline for the link*/}
                {/*              }}*/}
                {/*              onMouseEnter={() => {*/}
                {/*                const newIsLinkHovered = [...isLinkHoveredRight];*/}
                {/*                newIsLinkHovered[index] = true;*/}
                {/*                setIsLinkHoveredRight(newIsLinkHovered);*/}
                {/*              }}*/}
                {/*              onMouseLeave={() => {*/}
                {/*                const newIsLinkHovered = [...isLinkHoveredRight];*/}
                {/*                newIsLinkHovered[index] = false;*/}
                {/*                setIsLinkHoveredRight(newIsLinkHovered);*/}
                {/*              }}*/}
                {/*          >*/}
                {/*            <h5 className="card-title">*/}
                {/*              {ad.title}*/}
                {/*            </h5>*/}
                {/*          </a>*/}
                {/*          <p className="card-text">{ad.caption}</p>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*  ))}*/}
                {/*</Col>*/}






              </Row>
            </Container>

          </div>

        </Container>


  );

}

export default Home
