/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import './NavBar.css';
import { useNavigate } from "react-router";
import logo from "../logo/pop-zombie-v1.png";

import { FaSignInAlt, FaSignOutAlt, FaUser} from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from '../features/auth/authSlice'
import { TbSitemap } from "react-icons/tb";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const username = user ? `${user.firstName} ${user.lastName}` : '' ;
  const userImage = user && user.image ? <img src={user.image} alt = '' className='nav-image' /> : null;
  const handleLogout = async () => {
    try {
      dispatch(logout())
      dispatch(reset())
      setOpen(false);
      navigate("/");
    } catch (error) {
      return <p>{error.message}</p>;
    }
  };

  const bubblegumPalette = {
    pink: "#f17891",
    purple: "#b99ef5",
    yellow: "#fcf7e3",
    green: "#c3edbf",
    blue: "#b8dfe6",
    darkBlue: "#7f9ca9",
    bootstrapBlue: "#4892ff",
    bootstrapGreen: "#34b569",
    white: "#ffffff",
    ligthtBlue: "#15a2fa"
  };

  return (
      <nav className="navbar">
        <div className="nav-row nav-row-logo">
          <NavLink to="/" className="nav-logo">
            <img className="nav-img" src={logo} alt="logo" style={{ borderRadius:'50%' }} />
            Pop Zombie
          </NavLink>
          <div onClick={() => setOpen(!open)} className="nav-icon">
            {open ? <FiX /> : <FiMenu />}
          </div>
        </div>

        {user ? (
            <div className="nav-row">
              <ul className={open ? "nav-links active" : "nav-links"}>
                <li className="nav-item">
                  <NavLink
                      to="/"
                      className={({ isActive }) =>
                          isActive ? "active nav-link" : "inactive nav-link"
                      }
                      onClick={() => setOpen(false)}
                      style={{ color: bubblegumPalette.ligthtBlue }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      to="/userHome"
                      className={({ isActive }) =>
                          isActive ? "active nav-link" : "inactive nav-link"
                      }
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    My Posts
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      to="/favoritePost"
                      className={({ isActive }) =>
                          isActive ? "active nav-link" : "inactive nav-link"
                      }
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    My Favorites
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      to={`/profile/${user._id}`}
                      className={({ isActive }) =>
                          isActive ? "active nav-link" : "inactive nav-link"
                      }
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    My Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      to="/siteIndex"
                      className={({ isActive }) =>
                          isActive ? "active nav-link" : "inactive nav-link"
                      }
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    <TbSitemap /> Site Index
                  </NavLink>
                </li>
                <li className="nav-item">
                  <button
                      className="nav-btn"
                      onClick={handleLogout}
                      style={{ backgroundColor: '#ffffff', color: '#15a2fa', borderColor: '#ffffff' }}
                  >
                    <FaSignOutAlt /> Logout
                  </button>
                </li>
                <li className="nav-item">
                  {userImage ? (
                      userImage
                  ) : (
                      <div className="nav-text" style={{ color: '#15a2fa' }}>
                        {username}
                      </div>
                  )}
                </li>
              </ul>
            </div>
        ) : (
            <div className="nav-row">
              <ul className={open ? "nav-links active" : "nav-links"}>
                <li className="nav-item">
                  <NavLink
                      to="/"
                      className="nav-link"
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      to="/siteIndex"
                      className={({ isActive }) =>
                          isActive ? "active nav-link" : "inactive nav-link"
                      }
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    <TbSitemap /> Site Index
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      to="/login"
                      className="nav-link"
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    <FaSignInAlt /> Login
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      to="/register"
                      className="nav-link"
                      onClick={() => setOpen(false)}
                      style={{ color: '#15a2fa' }}
                  >
                    <FaUser /> Register
                  </NavLink>
                </li>
              </ul>
            </div>
        )}
      </nav>
  )
}

export default NavBar;
