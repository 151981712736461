/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from 'axios';
import { Card, Container, Row, Col, Button, NavLink } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import formatDistance from 'date-fns/formatDistance';
import CommentList from "../components/CommentList";
import CommentForm from "../components/CommentForm";
import default_image from '../logo/default_user.jpg';
import React, { useCallback } from 'react';
import ReactPlayer from 'react-player'
import ReactMarkdown from "react-markdown";
import './markdownStyles.css'
import { Helmet } from 'react-helmet'; // Import Helmet

const PostDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({});
  const [comments, setComments] = useState({});
  const [toggleForm, setToggleForm] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!slug) { return; }

    // Fetch post data based on slug
    axios.get(`/api/posts/post/${slug}`)
        .then(res => {
          // Once post data is fetched, set the post state
          const fetchedPost = res.data[0];
          setPost(fetchedPost);

          // Now that we have post._id, fetch comments for this post
          return axios.get(`/api/comments/post/${fetchedPost._id}`);
        })
        .then(res => {
          // Set comments state with fetched comments data
          setComments(res.data);
        })
        .catch(err => {
          // Handle any errors that occur during the fetch operations
          toast.error(err.message || "An error occurred");
        });
  }, [slug]);


  const isVideo = useCallback((url) => {
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    return videoExtensions.some((ext) => url && url.endsWith(ext));
  }, []);


  const toggleCommentForm = () => {
    setToggleForm(!toggleForm);
  };

  const refreshCommentData = async() => {
    await axios
      .get(`/api/comments/post/${post._id}`)
      .then(res => {
        setComments(res.data)
      })
      .catch(err => toast.error(err));
  }

  const addMyFavoritePost = async () => {
    if (!slug || !user) {
      toast.error('Post Id and user id are required');
    } else {
      const data = {slug};
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      await axios
        .post('/api/favoriteposts/', data, config)
        .then(res => {
          toast.success('The task has been done. Please visit your favorite posts page.');
          return res.data
        })
        .catch(err =>
          {
            if(err.response && err.response.status===400) {
              toast.error('The post already exists in your favorite post collection.');
            };
          });
    }
  };

  // Get author image
  const authorImage = post.author && post.author.image ? post.author.image : default_image;

  return (
      <Container fluid>
        <Helmet>
          <title>{post.title ? `${post.title} - Pop Zombie` : 'Post Detail - Pop Zombie'}</title>
          <link rel="canonical" href={`https://www.pop-zombie.com/postDetail/${post.country}`} />
          <meta property="og:url" content={`https://www.pop-zombie.com/postDetail/${post.country}`} />
          <meta
              name="description"
              content={post.content ? `${post.content.substring(0, 150)}...` : "Explore detailed post content on Pop Zombie."}
          />
          <meta property="og:description" content={post.content ? `${post.content.substring(0, 150)}...` : "Explore detailed post content on Pop Zombie."} />
          <meta property="og:title" content={post.title ? `${post.title} - Pop Zombie` : 'Post Detail - Pop Zombie'} />
          <link rel="alternate" hrefLang="en" href={`https://www.pop-zombie.com/postDetail/${post.country}`} />
          <link rel="alternate" hrefLang="x-default" href={`https://www.pop-zombie.com/postDetail/${post.country}`} />
        </Helmet>

        <Row>
          {/* Placeholder content column */}
          <Col md={2} />

          {/* Main content column */}
          <Col md={8}>

            <div className='title bottom__line' style={{
              backgroundColor: '#f0f2f5',
              color: "#15a2fa",
              marginTop:'8px',
              marginBottom:'8px',
              textAlign:'left'
            }}>
              {post.title}
            </div>

            <Card className='mb-2'
                  style={{
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    borderRadius: '15px',
                    padding:'0px'
                  }}>
              {post.image ? (
                  isVideo(post.image) ? (
                      <div className="banner">
                        <ReactPlayer className="w-100" url={post.image} controls />
                      </div>
                  ) : (
                      <Card.Img className="w-50"
                          src={post.image}
                          alt={post.title}
                          style={{
                            borderRadius: '10px'
                      }}
                      />
                  )
              ) : ''}
              <Card.Body>
                <Card.Text style={{ color: '#050505' }}>
                  <img src={authorImage} alt={post.author ? `${post.author.firstName} ${post.author.lastName}` : 'Author image'} className='author-small-image' />
                  {post.author ? ` ${post.author.firstName} ${post.author.lastName}` : ''}
                  {post.updatedAt ? ` - Last modified: ${formatDistance(new Date(post.updatedAt), new Date())}` : ''}
                </Card.Text>
              </Card.Body>
            </Card>

            <Card.Text style={{ color: '#15a2fa' }}>
              Category: {" "}<span style={{ color: '#ee3d5b' }}>{post.city}</span> - <span style={{ color: '#050505' }}>{post.country}</span>
            </Card.Text>

            {post.airBnBPrice ? (
                <a href={post.airBnBPrice} target="_blank" rel="noopener noreferrer">
                  <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#f0f2f5",
                        color: "#15a2fa",
                        borderColor: "#15a2fa",
                        marginBottom: "16px",
                        width:'30%'
                      }}
                  >
                    Visit Website
                  </Button>
                </a>
            ) : null}

            <div
                className="markdown-container"
                style={{
                  backgroundColor:'#ffffff',
                  padding:'15px',
                  borderRadius:'15px',
                  marginBottom:'15px',
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                }}
                dangerouslySetInnerHTML={{ __html: post.content }}
            ></div>

            {user && !toggleForm ?
                (<div className="mb-2">
                  <Button variant="outline-primary" type="submit" onClick={() => toggleCommentForm()}
                          style={{
                            backgroundColor: '#f0f2f5',
                            borderColor: '#f0f2f5',
                            color: '#15a2fa',
                            width:'30%',
                            marginBottom:'16px'
                          }}>
                    Add Comment
                  </Button>
                </div>)
                : ''
            }

            {post._id && (
                <div className={toggleForm ? 'show' : 'hide'}>
                  <Card.Body>
                    <CommentForm postId={post._id} user={user} toggleCommentForm={toggleCommentForm} refreshCommentData={refreshCommentData} />
                  </Card.Body>
                </div>
            )}

            {/*this is the background color of the comment list*/}
            <Card className='mb-2' style={{
              backgroundColor: '#ffffff',
              borderColor: '#ffffff',
              boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
              borderRadius: '15px',
            }}>
              <Card.Body>
                {comments && comments.length > 0 ? <CommentList comments={comments} refreshCommentData={refreshCommentData} /> : null}
              </Card.Body>
            </Card>

            <Button variant="outline-primary" type="submit" onClick={() => navigate('/')} style={{
              backgroundColor: '#f0f2f5',
              borderColor: '#f0f2f5',
              color: '#15a2fa',
              width: '20%',
              marginRight: '8px'
            }}
            >
              Home
            </Button>{"  "}


          </Col>

          {/* Placeholder content column */}
          <Col md={2} />
        </Row>
      </Container>
  );


}

export default PostDetail
