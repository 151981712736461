const siteLinks = [
    {
        "title": "Australian Shepherd",
        "url": "https://www.pop-zombie.com/postDetail/australian-shepherd"
    },
    {
        "title": "Yorkshire Terrier",
        "url": "https://www.pop-zombie.com/postDetail/yorkshire-terrier"
    },
    {
        "title": "Maltese Puppy",
        "url": "https://www.pop-zombie.com/postDetail/maltese-puppy"
    },
    {
        "title": "Labrador Retriever",
        "url": "https://www.pop-zombie.com/postDetail/labrador-retriever"
    },
    {
        "title": "Kufstein Fortress",
        "url": "https://www.pop-zombie.com/postDetail/kufstein-fortress"
    },
    {
        "title": "Reykjavik",
        "url": "https://www.pop-zombie.com/postDetail/reykjavik"
    },
    {
        "title": "South Island, New Zealand",
        "url": "https://www.pop-zombie.com/postDetail/south-island-new-zealand"
    },
    {
        "title": "Paris",
        "url": "https://www.pop-zombie.com/postDetail/paris"
    },
    {
        "title": "Maui",
        "url": "https://www.pop-zombie.com/postDetail/maui"
    },
    {
        "title": "Tahiti",
        "url": "https://www.pop-zombie.com/postDetail/tahiti"
    },
    {
        "title": "London",
        "url": "https://www.pop-zombie.com/postDetail/london"
    },
    {
        "title": "Rome",
        "url": "https://www.pop-zombie.com/postDetail/rome"
    },
    {
        "title": "Turks & Caicos",
        "url": "https://www.pop-zombie.com/postDetail/turks-and-caicos"
    },
    {
        "title": "Tokyo",
        "url": "https://www.pop-zombie.com/postDetail/tokyo"
    },
    {
        "title": "Maldives",
        "url": "https://www.pop-zombie.com/postDetail/maldives"
    },
    {
        "title": "Phuket",
        "url": "https://www.pop-zombie.com/postDetail/phuket"
    },
    {
        "title": "Barcelona",
        "url": "https://www.pop-zombie.com/postDetail/barcelona"
    },
    {
        "title": "Glacier National Park",
        "url": "https://www.pop-zombie.com/postDetail/glacier-national-park"
    },
    {
        "title": "Banff",
        "url": "https://www.pop-zombie.com/postDetail/banff"
    },
    {
        "title": "Grand Canyon",
        "url": "https://www.pop-zombie.com/postDetail/grand-canyon"
    },
    {
        "title": "Costa Rica",
        "url": "https://www.pop-zombie.com/postDetail/costa-rica"
    },
    {
        "title": "Bali",
        "url": "https://www.pop-zombie.com/postDetail/bali"
    },
    {
        "title": "New York City",
        "url": "https://www.pop-zombie.com/postDetail/new-york-city"
    },
    {
        "title": "Machu Picchu",
        "url": "https://www.pop-zombie.com/postDetail/machu-picchu"
    },
    {
        "title": "Sydney",
        "url": "https://www.pop-zombie.com/postDetail/sydney"
    },
    {
        "title": "Dubai",
        "url": "https://www.pop-zombie.com/postDetail/dubai"
    },
    {
        "title": "St. Lucia",
        "url": "https://www.pop-zombie.com/postDetail/st-lucia"
    },
    {
        "title": "Great Barrier Reef",
        "url": "https://www.pop-zombie.com/postDetail/great-barrier-reef"
    },
    {
        "title": "Florence",
        "url": "https://www.pop-zombie.com/postDetail/florence"
    },
    {
        "title": "Santorini",
        "url": "https://www.pop-zombie.com/postDetail/santorini"
    },
    {
        "title": "The Ultimate Travel Guide to Rio de Janeiro",
        "url": "https://www.pop-zombie.com/postDetail/the-ultimate-travel-guide-to-rio-de-janeiro"
    },
    {
        "title": "Amalfi Coast",
        "url": "https://www.pop-zombie.com/postDetail/amalfi-coast"
    },
    {
        "title": "Teotihuacan",
        "url": "https://www.pop-zombie.com/postDetail/teotihuacan"
    },
    {
        "title": "Neuschwanstein Castle",
        "url": "https://www.pop-zombie.com/postDetail/neuschwanstein-castle"
    },
    {
        "title": "Busan City, South Korea",
        "url": "https://www.pop-zombie.com/postDetail/busan-city-south-korea"
    },
    {
        "title": "Anse Source d'Argent, Seychelles",
        "url": "https://www.pop-zombie.com/postDetail/anse-source-dargent-seychelles"
    },
    {
        "title": "Hạ Long Bay, Vietnam",
        "url": "https://www.pop-zombie.com/postDetail/ha-long-bay-vietnam"
    },
    {
        "title": "Le Morne Brabant, Mauritius",
        "url": "https://www.pop-zombie.com/postDetail/le-morne-brabant-mauritius"
    },
    {
        "title": "Lucerne, Switzerland",
        "url": "https://www.pop-zombie.com/postDetail/lucerne-switzerland"
    },
    {
        "title": "Los Cabos, Mexico",
        "url": "https://www.pop-zombie.com/postDetail/los-cabos-mexico"
    },
    {
        "title": "Mount Fuji, Japan",
        "url": "https://www.pop-zombie.com/postDetail/mount-fuji-japan"
    },
    {
        "title": "Lake Como, Italy",
        "url": "https://www.pop-zombie.com/postDetail/lake-como-italy"
    },
    {
        "title": "Venice, Italy",
        "url": "https://www.pop-zombie.com/postDetail/venice-italy"
    },
    {
        "title": "Great Ocean Road, Australia",
        "url": "https://www.pop-zombie.com/postDetail/great-ocean-road-australia"
    },
    {
        "title": "Japan in Cherry Blossom Season",
        "url": "https://www.pop-zombie.com/postDetail/japan-in-cherry-blossom-season"
    },
    {
        "title": "Bora Bora, French Polynesia",
        "url": "https://www.pop-zombie.com/postDetail/bora-bora-french-polynesia"
    },
    {
        "title": "Forbidden City, Beijing, China",
        "url": "https://www.pop-zombie.com/postDetail/forbidden-city-beijing-china"
    },
    {
        "title": "Amalfi Coast, Italy",
        "url": "https://www.pop-zombie.com/postDetail/amalfi-coast-italy"
    },
    {
        "title": "St. Basil's Cathedral, Moscow, Russia",
        "url": "https://www.pop-zombie.com/postDetail/st-basils-cathedral-moscow-russia"
    },
    {
        "title": "Petra Jordan",
        "url": "https://www.pop-zombie.com/postDetail/petra-jordan"
    },
    {
        "title": "Istanbul, Turkey",
        "url": "https://www.pop-zombie.com/postDetail/istanbul-turkey"
    },
    {
        "title": "Jerusalem, Israel",
        "url": "https://www.pop-zombie.com/postDetail/jerusalem-israel"
    },
    {
        "title": "Pyramids of Giza, Egypt",
        "url": "https://www.pop-zombie.com/postDetail/pyramids-of-giza-egypt"
    },
    {
        "title": "Casablanca, Morroco",
        "url": "https://www.pop-zombie.com/postDetail/casablanca-morroco"
    },
    {
        "title": "Cancun, Mexico",
        "url": "https://www.pop-zombie.com/postDetail/cancun-mexico"
    },
    {
        "title": "Playa del Carmen, Mexico",
        "url": "https://www.pop-zombie.com/postDetail/playa-del-carmen-mexico"
    },
    {
        "title": "The Winter Palace",
        "url": "https://www.pop-zombie.com/postDetail/the-winter-palace"
    },
    {
        "title": "Unlock the Billionaire Mindset",
        "url": "https://www.pop-zombie.com/postDetail/unlock-the-billionaire-mindset"
    },
    {
        "title": "Proven Strategies to Get Rich Quickly",
        "url": "https://www.pop-zombie.com/postDetail/proven-strategies-to-get-rich-quickly"
    },
    {
        "title": "Billionaire Success Hacks",
        "url": "https://www.pop-zombie.com/postDetail/billionaire-success-hacks"
    },
    {
        "title": "7 Life-Changing Habits",
        "url": "https://www.pop-zombie.com/postDetail/7-life-changing-habits"
    },
    {
        "title": "The Ultimate Guide to Living Well",
        "url": "https://www.pop-zombie.com/postDetail/the-ultimate-guide-to-living-well"
    },
    {
        "title": "Vietnam: The Ideal Solo Traveler's Paradise for a Wallet-Friendly Adventure",
        "url": "https://www.pop-zombie.com/postDetail/vietnam-the-ideal-solo-travelers-paradise-for-a-wallet-friendly-adventure"
    },
    {
        "title": "Uncover the Magic: Top 10 Movie Locations to Embark on a Cinematic Adventure",
        "url": "https://www.pop-zombie.com/postDetail/uncover-the-magic-top-10-movie-locations-to-embark-on-a-cinematic-adventure"
    },
    {
        "title": "Discover the Enchanting Charm of San Miguel de Allende: Mexico's Hidden Gem",
        "url": "https://www.pop-zombie.com/postDetail/discover-the-enchanting-charm-of-san-miguel-de-allende-mexicos-hidden-gem"
    },
    {
        "title": "Double Trouble: 7 Reasons to Embark on a Summer Adventure with Your Best Friend",
        "url": "https://www.pop-zombie.com/postDetail/double-trouble-7-reasons-to-embark-on-a-summer-adventure-with-your-best-friend"
    },
    {
        "title": "Pawsome Adventures: 7 Pet-Friendly Travel Destinations and Road Trip Ideas for You and Your Furry Friend",
        "url": "https://www.pop-zombie.com/postDetail/pawsome-adventures"
    },
    {
        "title": "Unleash Your Inner Adventurer: 7 Video Game-Inspired Travel Destinations You Must Visit",
        "url": "https://www.pop-zombie.com/postDetail/unleash-your-inner-adventurer"
    },
    {
        "title": "A Triumphant Finale: \"The Lord of the Rings: The Return of the King",
        "url": "https://www.pop-zombie.com/postDetail/a-triumphant-finale-the-lord-of-the-rings-the-return-of-the-king"
    },
    {
        "title": "12 Years a Slave",
        "url": "https://www.pop-zombie.com/postDetail/12-years-a-slave"
    },
    {
        "title": "Gravity",
        "url": "https://www.pop-zombie.com/postDetail/gravity"
    },
    {
        "title": "Birdman or (The Unexpected Virtue of Ignorance)",
        "url": "https://www.pop-zombie.com/postDetail/birdman-or-the-unexpected-virtue-of-ignorance"
    },
    {
        "title": "No Country for Old Men",
        "url": "https://www.pop-zombie.com/postDetail/no-country-for-old-men"
    },
    {
        "title": "Mount Ruapehu, Tongariro National Park",
        "url": "https://www.pop-zombie.com/postDetail/mount-ruapehu-tongariro-national-park"
    },
    {
        "title": "Rangitikei River, North Island",
        "url": "https://www.pop-zombie.com/postDetail/rangitikei-river-north-island"
    },
    {
        "title": "Putangirua Pinnacles, North Island",
        "url": "https://www.pop-zombie.com/postDetail/putangirua-pinnacles-north-island"
    },
    {
        "title": "Twizel, South Island",
        "url": "https://www.pop-zombie.com/postDetail/twizel-south-island"
    },
    {
        "title": "Glenorchy, South Island",
        "url": "https://www.pop-zombie.com/postDetail/glenorchy-south-island"
    },
    {
        "title": "Deer Park Heights, Queenstown",
        "url": "https://www.pop-zombie.com/postDetail/deer-park-heights-queenstown"
    },
    {
        "title": "Bocage Plantation in Darrow, Louisiana",
        "url": "https://www.pop-zombie.com/postDetail/bocage-plantation-in-darrow-louisiana"
    },
    {
        "title": "Destrehan Plantation in Destrehan, Louisiana",
        "url": "https://www.pop-zombie.com/postDetail/destrehan-plantation-in-destrehan-louisiana"
    },
    {
        "title": "Felicity Plantation in Vacherie, Louisiana",
        "url": "https://www.pop-zombie.com/postDetail/felicity-plantation-in-vacherie-louisiana"
    },
    {
        "title": "Holy Cross School on 43rd Street between 8th and 9th Avenues",
        "url": "https://www.pop-zombie.com/postDetail/holy-cross-school-on-43rd-street-between-8th-and-9th-avenues"
    },
    {
        "title": "Times Square",
        "url": "https://www.pop-zombie.com/postDetail/times-square"
    },
    {
        "title": "Kaufman Astoria Studios in Astoria, Queens",
        "url": "https://www.pop-zombie.com/postDetail/kaufman-astoria-studios-in-astoria-queens"
    },
    {
        "title": "St. James Theatre on 44th Street between 7th and 8th Avenues",
        "url": "https://www.pop-zombie.com/postDetail/st-james-theatre-on-44th-street-between-7th-and-8th-avenues"
    },
    {
        "title": "Magnolia Plantation in Schriever, Louisiana",
        "url": "https://www.pop-zombie.com/postDetail/magnolia-plantation-in-schriever-louisiana"
    },
    {
        "title": "Lake Powell in Arizona, United States",
        "url": "https://www.pop-zombie.com/postDetail/lake-powell-in-arizona-united-states"
    },
    {
        "title": "Pinewood Studios in Iver Heath, Buckinghamshire, England",
        "url": "https://www.pop-zombie.com/postDetail/pinewood-studios-in-iver-heath-buckinghamshire-england"
    },
    {
        "title": "Madame John's Legacy in the French Quarter of New Orleans",
        "url": "https://www.pop-zombie.com/postDetail/madame-johns-legacy-in-the-french-quarter-of-new-orleans"
    },
    {
        "title": "The Columns Hotel in the Garden District of New Orleans",
        "url": "https://www.pop-zombie.com/postDetail/the-columns-hotel-in-the-garden-district-of-new-orleans"
    },
    {
        "title": "Shepperton Studios in Shepperton, Surrey, England",
        "url": "https://www.pop-zombie.com/postDetail/shepperton-studios-in-shepperton-surrey-england"
    },
    {
        "title": "Audubon Park in New Orleans",
        "url": "https://www.pop-zombie.com/postDetail/audubon-park-in-new-orleans"
    },
    {
        "title": "Piedras Negras, Mexico",
        "url": "https://www.pop-zombie.com/postDetail/piedras-negras-mexico"
    },
    {
        "title": "The Rum House on 47th Street between Broadway and 8th Avenues",
        "url": "https://www.pop-zombie.com/postDetail/the-rum-house-on-47th-street-between-broadway-and-8th-avenues"
    },
    {
        "title": "Albuquerque, New Mexico",
        "url": "https://www.pop-zombie.com/postDetail/albuquerque-new-mexico"
    },
    {
        "title": "Las Vegas, New Mexico",
        "url": "https://www.pop-zombie.com/postDetail/las-vegas-new-mexico"
    },
    {
        "title": "Marfa, Texas",
        "url": "https://www.pop-zombie.com/postDetail/marfa-texas"
    },
    {
        "title": "Harry Potter and the Prisoner of Azkaban",
        "url": "https://www.pop-zombie.com/postDetail/harry-potter-and-the-prisoner-of-azkaban"
    },
    {
        "title": "Christ Church College - Oxford, England",
        "url": "https://www.pop-zombie.com/postDetail/christ-church-college-oxford-england"
    },
    {
        "title": "Clachaig Gully - Scotland",
        "url": "https://www.pop-zombie.com/postDetail/clachaig-gully-scotland"
    },
    {
        "title": "Glenfinnan Viaduct - Scotland",
        "url": "https://www.pop-zombie.com/postDetail/glenfinnan-viaduct-scotland"
    },
    {
        "title": "Loch Shiel - Scotland",
        "url": "https://www.pop-zombie.com/postDetail/loch-shiel-scotland"
    },
    {
        "title": "Goathland Station - North Yorkshire, England",
        "url": "https://www.pop-zombie.com/postDetail/goathland-station-north-yorkshire-england"
    },
    {
        "title": "Gloucester Cathedral - Gloucester, England",
        "url": "https://www.pop-zombie.com/postDetail/gloucester-cathedral-gloucester-england"
    },
    {
        "title": "Alnwick Castle - Alnwick, Northumberland, England",
        "url": "https://www.pop-zombie.com/postDetail/alnwick-castle-alnwick-northumberland-england"
    },
    {
        "title": "Introducing Wagzters: A Haven for Pet Lovers and Their Furry Friends!",
        "url": "https://www.pop-zombie.com/postDetail/introducing-wagzters"
    },
    {
        "title": "Dogoku: Unleash Your Puzzle-Solving Instincts with a Wild Animal Sudoku",
        "url": "https://www.pop-zombie.com/postDetail/dogoku-unleash-your-puzzle-solving-instincts-with-a-wild-animal-sudoku"
    },
    {
        "title": "Catdoku: Online Multiplayer Cat-Themed Sudoku Game",
        "url": "https://www.pop-zombie.com/postDetail/catdoku-online-multiplayer"
    },
    {
        "title": "Rio de Janeiro: A Vibrant Tapestry of Culture and Nature",
        "url": "https://www.pop-zombie.com/postDetail/rio-de-janeiro-a-vibrant-tapestry-of-culture-and-nature"
    },
    {
        "title": "Best Time to Visit Rio de Janeiro: A Traveler's Guide",
        "url": "https://www.pop-zombie.com/postDetail/best-time-to-visit-rio-de-janeiro"
    },
    {
        "title": "Carnival in Rio: The Ultimate Celebration of Life",
        "url": "https://www.pop-zombie.com/postDetail/carnival-in-rio"
    },
    {
        "title": "Safety in Rio de Janeiro: Navigating the Marvelous City with Confidence",
        "url": "https://www.pop-zombie.com/postDetail/safety-in-rio"
    },
    {
        "title": "Travel Rio de Janeiro: A Lively Journey Through the Heart of Brazil",
        "url": "https://www.pop-zombie.com/postDetail/travel-tips-for-rio"
    },
    {
        "title": "Copacabana Beach: Rio de Janeiro's Iconic Seaside Gem",
        "url": "https://www.pop-zombie.com/postDetail/copacabana-beach"
    },
    {
        "title": "Savor the Flavors of Rio: A Culinary Journey Through the City's Iconic Dishes",
        "url": "https://www.pop-zombie.com/postDetail/rio-cuisine"
    },
    {
        "title": "Exploring the Richness of Brazilian Cuisine: A Journey Through Taste and Tradition",
        "url": "https://www.pop-zombie.com/postDetail/brazilian-cuisine"
    },
    {
        "title": "Discover South America: A Tapestry of Destinations",
        "url": "https://www.pop-zombie.com/postDetail/south-america-destinations"
    },
    {
        "title": "The Vibrant Pulse of Brazil: Samba's Irresistible Rhythm",
        "url": "https://www.pop-zombie.com/postDetail/samba-culture"
    },
    {
        "title": "Top Beachfront Hotels in Rio de Janeiro for a Sun-Soaked Getaway",
        "url": "https://www.pop-zombie.com/postDetail/beachfront-hotels-in-rio"
    },
    {
        "title": "Budget-Friendly Hostels in Rio de Janeiro for a Vibrant Stay",
        "url": "https://www.pop-zombie.com/postDetail/hostels-in-rio"
    },
    {
        "title": "Indulge in Elegance: Luxury Hotels in Rio de Janeiro",
        "url": "https://www.pop-zombie.com/postDetail/boutique-lodgings-in-rio"
    },
    {
        "title": "Boolean Logic Simplification Showdown: NebulaSolver vs. Karnaugh Maps",
        "url": "https://www.pop-zombie.com/postDetail/boolean-logic-simplification-showdown-nebulasolver-vs-karnaugh-maps"
    },
    {
        "title": "Solving Non-Linear Equations: NebulaSolver's Enhanced Capabilities",
        "url": "https://www.pop-zombie.com/postDetail/solving-non-linear-equations-nebulasolver-enhanced-capabilities"
    },
    {
        "title": "Linear Algebra with NebulaSolver: Determinants, Eigenvalues, and More",
        "url": "https://www.pop-zombie.com/postDetail/mastering-linear-algebra-with-nebulasolver-determinants-eigenvalues-and-more"
    },
    {
        "title": "Feedback Control Systems with NebulaSolver",
        "url": "https://www.pop-zombie.com/postDetail/feedback-control-systems-with-nebulasolver"
    },
    {
        "title": "Exploring Truss Structures with NebulaSolver's 2D-Truss App",
        "url": "https://www.pop-zombie.com/postDetail/exploring-truss-structures-with-nebulasolvers-2d-truss-app"
    },
    {
        "title": "Beam Analysis with NebulaSolver's App",
        "url": "https://www.pop-zombie.com/postDetail/exploring-beam-analysis-with-nebulasolvers-app"
    },
    {
        "title": "Visualizing Thermodynamic Diagrams with NebulaSolver's App",
        "url": "https://www.pop-zombie.com/postDetail/visualizing-thermodynamic-diagrams-with-nebulasolvers-app"
    },
    {
        "title": "Battery Performance with NebulaSolver's BMS Simulation App",
        "url": "https://www.pop-zombie.com/postDetail/battery-performance-with-nebulasolvers-bms-simulation-app"
    },
    {
        "title": "Circuit Simulations with NebulaSolver's App",
        "url": "https://www.pop-zombie.com/postDetail/circuit-simulations-with-nebulasolvers-app"
    },
    {
        "title": "Systems Engineering with NebulaSolver's FMU-Based Simulations",
        "url": "https://www.pop-zombie.com/postDetail/systems-engineering-with-nebulasolvers-fmu-based-simulations"
    },
    {
        "title": "Browser-Based Diagramming with NebulaSolver",
        "url": "https://www.pop-zombie.com/postDetail/browser-based-diagramming-with-nebulasolver"
    },
    {
        "title": "Project Management with Gantt Charts",
        "url": "https://www.pop-zombie.com/postDetail/project-management-with-gantt-charts"
    },
    {
        "title": "Visualizing Stock Performance: Key Metrics and Effective Plots",
        "url": "https://www.pop-zombie.com/postDetail/visualizing-stock-performance-key-metrics-and-effective-plots"
    }
];

export default siteLinks;
