const Footer = () => {
    return (
        <footer className="page-footer font-small mt-2 top__line" style={{
            backgroundColor: '#f0f2f5',
            color: '#15a2fa'
        }}>
            <div className="footer-copyright text-center py-2">
                © 2024 Pop Zombie
            </div>
        </footer>
    )
}

export default Footer
